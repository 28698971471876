import {
  ref,
} from '@vue/composition-api'
import axiosInstances from '@/libs/axios-instances'
import router from '@/router'

export default function getAnnouncements() {
  const announcementData = ref(null)

  const convertStatusToBoolean = () => {
    announcementData.value.enable_share = !!announcementData.value.enable_share
    announcementData.value.enable_comments = !!announcementData.value.enable_comments
    announcementData.value.enable_likes = !!announcementData.value.enable_likes
    announcementData.value.enable_status = !!announcementData.value.enable_status
  }

  const getAnnouncement = () => {
    axiosInstances.activities.get(`internalops/announcement-activities/${router.currentRoute.params.id}/show`).then(res => {
      announcementData.value = res.data.data
      convertStatusToBoolean()
    })
  }
  getAnnouncement()

  return {
    announcementData,
  }
}
