import axiosInstances from '@/libs/axios-instances'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default function comments() {
  const { successfulOperationAlert, failedOperationAlert } = handleAlerts()

  const deleteCommentRequest = commentId => new Promise((resolve, reject) => {
    axiosInstances.activities.delete(`gateway/deleteComment/${commentId}`).then(() => {
      successfulOperationAlert('Comment is deleted successfully')
      resolve()
    }).catch(() => {
      failedOperationAlert('Failed to delete comment')
      reject()
    })
  })

  const deleteReplyRequest = replyId => new Promise((resolve, reject) => {
    axiosInstances.activities.delete(`gateway/deleteReply/${replyId}`).then(() => {
      successfulOperationAlert('Reply is deleted successfully')
      resolve()
    }).catch(() => {
      failedOperationAlert('Failed to delete reply')
      reject()
    })
  })

  return {
    deleteCommentRequest,
    deleteReplyRequest,
  }
}
