<template>
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="100"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Slides with image only -->
      <div
        v-for="(image,index) in images"
        :key="index"
      >
        <b-carousel-slide
          :img-src="`${$activitiesImagesURL}${image.link}`"
        />
      </div>
    </b-carousel>
  </div>
</template>

<script>

export default {
  name: 'AnnouncementsCarousel',
  props: {
    images: { type: Array, default: null },
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    }
  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
  },
}
</script>
<style lang="scss">
.carousel-item img {
  height: 65vh;
  min-height: 350px;
  background: no-repeat center center scroll;
  background-size: cover;
}
</style>
