<template>
  <!-- Comments -->
  <b-media>
    <template #aside>
      <b-avatar
        src=""
        :text="avatarText(commentDetails.user.name)"
      />
    </template>
    <h5 class="mt-0">
      {{ commentDetails.user.name }}
    </h5>
    <b-button
      variant="danger"
      size="sm"
      class="d-block ml-auto"
      @click="deleteComment(commentDetails.id)"
    >
      <feather-icon
        icon="Trash2Icon"
        size="20"
      />
    </b-button>
    <p class="font-weight-bolder">
      {{ commentDetails.comment }}
    </p>
    <small>{{ commentDetails.created_at }}</small>
    <h5 class="likes mt-2">
      {{ commentDetails.comment_likes.length }}
      Likes
      <feather-icon
        icon="ThumbsUpIcon"
        size="20"
      />
    </h5>
    <h5
      v-if="commentDetails.replies.length !==0"
      class="mt-2 pt-2 border-top"
    >
      {{ commentDetails.replies.length }} Replies
    </h5>

    <!-- Replies -->
    <b-media
      v-for="reply in commentDetails.replies"
      :key="reply.id"
      class="mt-2"
    >
      <template #aside>
        <b-avatar
          size="32"
          src=""
          :text="avatarText(reply.user.name)"
        />
      </template>

      <h5>
        {{ reply.user.name }}
      </h5>

      <b-button
        variant="danger"
        size="sm"
        class="d-block ml-auto"
        @click="deleteReply(reply.id)"
      >
        <feather-icon
          icon="Trash2Icon"
          size="20"
        />
      </b-button>

      <p>
        {{ reply.comment }}
      </p>
      <small>{{ reply.created_at }}</small>
      <h5 class="likes mt-2">
        {{ reply.reply_likes.length }}
        Likes
        <feather-icon
          icon="ThumbsUpIcon"
          size="20"
        />
      </h5>
    </b-media>
  </b-media>
</template>
<script>
import { avatarText } from '@core/utils/filter'
import deleteCommentsComposition from '@/common/compositions/Announcements/deleteComments'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'AnnouncementComments',
  props: {
    commentDetails: { type: Object, default: () => {} },
  },
  data() {
    return {
      deleteMessage: 'Are you sure you want to delete this comment?',
    }
  },
  setup() {
    const { confirmDelete } = handleAlerts()

    const {
      deleteCommentRequest,
      deleteReplyRequest,
    } = deleteCommentsComposition()

    return {
      deleteCommentRequest,
      deleteReplyRequest,
      confirmDelete,
    }
  },
  methods: {
    avatarText,
    deleteComment(commentId) {
      this.confirmDelete(this.deleteMessage).then(() => {
        this.deleteCommentRequest(commentId).then(() => {
          this.refreshComments()
        })
      })
    },
    deleteReply(replyId) {
      this.confirmDelete(this.deleteMessage).then(() => {
        this.deleteReplyRequest(replyId).then(() => {
          this.refreshComments()
        })
      })
    },
    refreshComments() {
      this.$emit('deleteComment')
    },
  },
}
</script>
<style lang="scss">

</style>
