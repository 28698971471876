<template>
  <div v-if="announcementData !== null">
    <b-row
      v-if="announcementData.media_files[0] && announcementData.media_files[0].media_type === 'video'"
      align-h="center"
      class="mb-3"
    >
      <iframe
        width="60%"
        height="500"
        :src="`https://www.youtube.com/embed/${getId(announcementData.media_files[0].link)}`"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </b-row>
    <carousel
      v-else-if="announcementData.media_files[0]"
      :images="announcementData.media_files"
    />
    <b-row>
      <b-col>
        <b-card
          img-top
          img-alt="Blog Detail Pic"
          :title="announcementData.activity_name"
        >
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="blog-content"
            v-html="announcementData.activity_description"
          />
          <hr>
          <div>
            <small>
              <feather-icon
                icon="HeartIcon"
                size="20"
              /> {{ announcementData.likes_count }} likes
            </small>
            <small class="ml-1">
              <feather-icon
                icon="Share2Icon"
                size="20"
              /> {{ announcementData.shares_count }} share
            </small>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="mb-2">
          Comments
        </h2>
        <b-card
          v-for="commentDetails in comments"
          :key="commentDetails.id"
        >
          <comments
            :comment-details="commentDetails"
            @deleteComment="getAnnouncementComments"
          />
        </b-card>
      </b-col>
    </b-row>
    <pagination
      :per-page="pagination.perPage"
      :total-rows="pagination.totalRows"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import Carousel from '@/common/components/Announcements/Carousel.vue'
import Comments from '@/common/components/Announcements/Comments.vue'
import getAnnouncements from '@/common/compositions/Announcements/getAnnouncements'
import Pagination from '@/common/components/common/Table/Pagination.vue'

export default {
  components: {
    Carousel,
    Comments,
    Pagination,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      comments: [],
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalRows: null,
      },
    }
  },
  setup() {
    const { announcementData } = getAnnouncements()

    return {
      announcementData,
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.pagination.currentPage
      },
    },
  },
  watch: {
    currentPage() {
      this.getAnnouncementComments()
    },
  },
  created() {
    this.getAnnouncementComments()
  },
  methods: {
    getAnnouncementComments() {
      this.$activities.post('gateway/announcementComment', {}, {
        params: {
          announcement_id: this.$router.currentRoute.params.id,
          page: this.pagination.currentPage,
        },
      }).then(res => {
        this.comments = res.data.data
        this.pagination.perPage = res.data.pagination.per_page
        this.pagination.totalRows = res.data.pagination.total
      })
    },
    getId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      const match = url.match(regExp)

      return (match && match[2].length === 11)
        ? match[2]
        : null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
